<template>
	<div>
		<div class="title">{{ data.title }}</div>
		<div class="conte" v-html="data.content"></div>
	</div>
</template>

<script>
import utils from '@/common/utils';
export default {
	data() {
		return {
			data: {}
		};
	},
	mounted() {
		// console.log(this.$route.query.id);
		this.getList(this.$route.query.id);
	},
	methods: {
		getList(id) {
			utils.showSpin();
			this.http.get(`/api/common/notice/detail/${id}`, this.param).then(ret => {
				console.log(ret.data);
				this.data = ret.data;
				utils.closeSpin();
			});
		}
	}
};
</script>

<style scoped>
.conte {
	width: 100%;
	margin: 0 auto;
	text-indent: 2em;
	margin-top: 10px;
}
.title {
	font-size: 35px;
	text-align: center;
	line-height: 70px;
	border-bottom: 1px solid #ccc;
}
</style>
